import React, { useState } from "react";
import { Grid, Card, CardMedia, Dialog, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
const images = [
  { src: "/Works/1.jpg", title: "Image 1" },
  { src: "/Works/2.jpg", title: "Image 2" },
  { src: "/Works/3.jpg", title: "Image 2" },
  { src: "/Works/4.jpg", title: "Image 2" },
  { src: "/Works/5.jpg", title: "Image 2" },
  { src: "/Works/6.jpg", title: "Image 2" },
  { src: "/Works/7.jpg", title: "Image 2" },
  { src: "/Works/8.jpg", title: "Image 2" },
  { src: "/Works/9.jpg", title: "Image 2" },
  { src: "/Works/10.jpg", title: "Image 2" },
  { src: "/Works/12.jpg", title: "Image 2" },
  { src: "/Works/13.jpg", title: "Image 2" },
  { src: "/Works/14.jpg", title: "Image 2" },
];
const MainContainer = styled("div")({
  border: "22px solid #0766AD",
  borderRadius: "7px", // Adjusted the border for simplicity
  padding: "1px",
  marginBlock: "2",
  display: "-ms-flexbox",
  boxSizing: "border-box",
  height: 840,
  background: "#CDF5FD",
  overflow: "auto",
});
export default function ImageGallery() {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MainContainer>
      <Grid container spacing={2} padding="16px">
        {images.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card onClick={() => handleOpen(image.src)}>
              <CardMedia
                component="img"
                height="140"
                image={image.src}
                alt={image.title}
              />
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ overflow: "hidden" }}>
          <img src={selectedImage} alt="Enlarged" style={{ width: "100%" }} />
        </Box>
      </Dialog>
    </MainContainer>
  );
}
