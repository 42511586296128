import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// Styled component for the individual digit boxes
const DigitBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  minWidth: "1em", // Ensures each box has the same width
  padding: theme.spacing(1),
  margin: theme.spacing(0.5),
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
}));

const VisitorCounter = ({ count }) => {
  // Convert count to an array of single digits
  const digits = String(count).padStart(6, "0").split("");

  return (
    <Box sx={{ display: "flex" }}>
      {digits.map((digit, index) => (
        <DigitBox key={index}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {digit}
          </Typography>
        </DigitBox>
      ))}
    </Box>
  );
};

export default VisitorCounter;
