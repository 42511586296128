import React, { useState } from "react";
import {
  Card,
  Paper,
  CardContent,
  Grid,
  Box,
  Typography,
  Avatar,
  TextField,
  Autocomplete,
  IconButton,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LogoImage from "./log.jpg";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import InsertLinkTwoToneIcon from "@mui/icons-material/InsertLinkTwoTone";
import { useNavigate, useParams } from "react-router-dom";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import EngineeringRoundedIcon from "@mui/icons-material/EngineeringRounded";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import UnderConstruction from "./UnderConstruction";

const getRandomColor = () => {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 8)];
  }
  return color;
};

const colors = [
  "#FF5733", // Red
  "#33FF57", // Green
  "#3357FF", // Blue
  "#F833FF", // Magenta
  "#33FFF8", // Cyan
  // ...add as many colors as you like
];

// Function to generate random gradient
function getRandomGradient() {
  const color1 = colors[Math.floor(Math.random() * colors.length)];
  let color2 = colors[Math.floor(Math.random() * colors.length)];

  // Ensure color2 is different from color1
  while (color1 === color2) {
    color2 = colors[Math.floor(Math.random() * colors.length)];
  }

  return `linear-gradient(45deg, ${color1}, ${color2})`;
}
// Define JSON data for the card properties
const jsonData = [
  {
    cid: 1,
    id: 11,
    title: "Works",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <WorkspacePremiumIcon />, // Path to the logo image
    color: "#3C486B",
  },
  {
    cid: 1,
    id: 12,
    title: "AMC",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <WorkspacePremiumIcon />, // Path to the logo image
    color: "#6EC95E",
  },
  {
    cid: 1,
    id: 13,
    title: "HR & CE Institutions",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <WorkspacePremiumIcon />, // Path to the logo image
    color: "#B71616",
  },
  {
    cid: 1,
    id: 14,
    title: "PRIs",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <WorkspacePremiumIcon />, // Path to the logo image
    color: "#208C6E",
  },
  {
    cid: 1,
    id: 15,
    title: "ULBs",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <WorkspacePremiumIcon />, // Path to the logo image
    color: "#2112A7",
  },
  {
    cid: 5,
    id: 51,
    title: "State Audit",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <InsertLinkTwoToneIcon />,
    color: getRandomColor(),
  },
  {
    cid: 5,
    id: 52,
    title: "CAG",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <InsertLinkTwoToneIcon />,
    color: getRandomColor(),
  },
  {
    cid: 5,
    id: 53,
    title: "Government Orders",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <InsertLinkTwoToneIcon />,
    color: getRandomColor(),
  },
  {
    cid: 5,
    id: 54,
    title: "Pay and establishment",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <InsertLinkTwoToneIcon />,
    color: getRandomColor(),
  },
  {
    cid: 5,
    id: 55,
    title: "Auditee institutions",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <InsertLinkTwoToneIcon />,
    color: getRandomColor(),
  },
  {
    cid: 5,
    id: 56,
    title: "Works Audit",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <InsertLinkTwoToneIcon />,
    color: getRandomColor(),
  },
  {
    cid: 5,
    id: 57,
    title: "Drivers & Software",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <InsertLinkTwoToneIcon />,
    color: getRandomColor(),
  },
  {
    cid: 2,
    id: 21,
    title: "Establishment",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <FileDownloadRoundedIcon />,
    color: getRandomColor(),
  },
  {
    cid: 2,
    id: 22,
    title: "Audit",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <FileDownloadRoundedIcon />,
    color: getRandomColor(),
  },
  {
    cid: 7,
    id: 71,
    title: "Parabank",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <AccountBalanceWalletRoundedIcon />,
    color: getRandomColor(),
  },
  {
    cid: 9,
    id: 91,
    title: "Videos",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <OndemandVideoIcon />,
    color: getRandomColor(),
  },
  {
    cid: 10,
    id: 101,
    title: "Works",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <EngineeringRoundedIcon />,
    color: getRandomColor(),
  },
  {
    cid: 3,
    id: 301,
    title: "Chat Room",
    content:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    logoSrc: <EngineeringRoundedIcon />,
    color: getRandomColor(),
  },
];

const MainContainer = styled("div")({
  border: "22px solid #0766AD",
  borderRadius: "7px", // Adjusted the border for simplicity
  padding: "1px",
  marginBlock: "2",
  display: "-ms-flexbox",
  boxSizing: "border-box",
  height: 840,
  background: "#CDF5FD",
});

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 325,
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    transform: "scale(1.23)",
  },
  transition: "transform 0.3s ease-in-out",
}));

const CardHeader = styled(Paper)(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor,
  color: "white",
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const FlipCardContent = styled(CardContent)({
  backfaceVisibility: "hidden",
  transition: "transform 0.5s",
  transformStyle: "preserve-3d",
});
const CustomCard = ({ cid, parentTitle, card, index }) => {
  let navigate = useNavigate();
  const [flipped, setFlipped] = useState(false);

  const handleClick = (e) => {
    const id = card.id;
    const title = card.title;
    // const parentTitle=card.parentTitle;
    console.log("inside handle click" + id);
    console.log(parentTitle);
    setFlipped(!flipped);

    if (id === 101) {
      navigate("/album");
    } else if (id === 91) {
      navigate("/video");
    }
    // } else if (id === 301) {
    //   navigate("/chat");
    // }
    else {
      navigate(`/${parentTitle}/${title}`, { state: { subId: id, cid } });
    }
  };

  return (
    <Grid item xs={4} sm={6} md={4} onClick={handleClick}>
      <StyledCard raised={flipped}>
        <CardHeader bgcolor={card.color} elevation={3}>
          <Avatar src={LogoImage} />
          <Typography variant="h6" component="h2">
            {card.title}
          </Typography>
          {card.logoSrc}
        </CardHeader>
        <FlipCardContent
          style={{ transform: flipped ? "rotateY(90deg)" : "rotateY(0deg)" }}
        >
          <Typography variant="body2" component="p">
            {card.content}
          </Typography>
        </FlipCardContent>
      </StyledCard>
    </Grid>
  );
};

export default function SecondGrid(props) {
  const { title } = useParams();
  // const [searchTerm, setSearchTerm] = useState('');
  // const [flData, setFldata] = useState(jsonData);
  const location = useLocation();
  const cid = location.state.cid;
  console.log("cid " + cid);
  // Filter the jsonData based on the cid captured
  var filteredData = jsonData.filter((card) => card.cid === cid);

  // const handleSearch = (event) => {
  //   const value = event.target.value;
  //   console.log("Data"+value)
  //   setSearchTerm(value);
  //   const filtered = jsonData.filter((item) => item.title.toString().includes(value.toString()));
  //   console.log("Json"+filtered)
  //   setFldata(filtered);

  // };
  // <Box sx={{ display: 'flex', alignItems: 'center', marginLeft:170,marginRight:20, p: 1 }}>
  // <Autocomplete
  //   freeSolo
  //   options={jsonData.map((option) => option.title)}
  //   value={searchTerm}
  //   sx={{marginRight:250,marginLeft:250}}
  //   onChange={(event, newValue) => {
  //     setSearchTerm(newValue);
  //   }}
  //   renderInput={(params) => (
  //     <TextField
  //       {...params}
  //       label="Search by Title"
  //       InputProps={{
  //         ...params.InputProps,
  //         endAdornment: (
  //           <IconButton
  //             onClick={() => handleSearch(searchTerm)}
  //             sx={{ p: '10px'}}
  //             aria-label="search"
  //           >
  //             <SearchIcon />
  //           </IconButton>

  //         ),
  //       }}
  //     />
  //   )}
  //   sx={{ flex: 1 }}
  // />
  // </Box>

  return (
    <>
      <MainContainer>
        <Box>
          <h1 style={{ color: "#0766AD", textAlign: "center" }}>{title}</h1>
          {cid === 3 || cid === 4 || cid === 6 || cid === 8 || cid === 11 ? (
            <div>
              <UnderConstruction />
            </div>
          ) : (
            <Grid
              container
              spacing={2}
              sx={{
                marginBottom: "45px",
                display: "flex",
                marginLeft: "65px",
                marginTop: "25px",
              }}
            >
              {filteredData.map((card, index) => (
                <CustomCard
                  key={card.id}
                  card={card}
                  parentTitle={title}
                  cid={cid}
                />
              ))}
            </Grid>
          )}
        </Box>
      </MainContainer>
    </>
  );
}
