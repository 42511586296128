import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import PdfViewerPopup from "./Pdfpopup";
import { useLocation } from "react-router-dom";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
// Sample JSON Data - replace with actual data fetching in a real app
const tablesData = [
  {
    id: 11,
    tableId: 1,
    title: "Third Party Quality Control(Materials)",
    rows: [
      {
        id: 1,
        name: "BITUMEN",
        view: "/Templates/Templates/quality/BITUMEN.pdf",
      },
      { id: 2, name: "BRICK", view: "/Templates/Templates/quality/BRICK.pdf" },
      {
        id: 3,
        name: "CEMENT",
        view: "/Templates/Templates/quality/CEMENT.pdf",
      },
      {
        id: 4,
        name: "CONCRETE",
        view: "/Templates/Templates/quality/CONCRETE.pdf",
      },
      {
        id: 5,
        name: "HDPE PIPES",
        view: "/Templates/Templates/quality/HDPE PIPES.pdf",
      },
      { id: 5, name: "SOIL", view: "/Templates/Templates/quality/SOIL.pdf" },
      { id: 5, name: "STEEL", view: "/Templates/Templates/quality/STEEL.pdf" },
    ],
  },
  {
    id: 11,
    tableId: 2,
    title: "Audit Checks",
    rows: [
      {
        id: 1,
        name: "AUDIT CHECKS OF CC ROADS",
        view: "/Templates/Templates/Audit Checks/AUDIT CHECKS OF CC ROADS.pdf",
        download1:
          "/Templates/Templates/Audit Checks/AUDIT CHECKS OF CC ROADS.docx",
        download2: null,
      },
      {
        id: 2,
        name: "AUDIT CHECKS OF COMPOUND WALL WORKS",
        view: "/Templates/Templates/Audit Checks/AUDIT CHECKS OF COMPOUND WALL WORKS.pdf",
        download1:
          "/Templates/Templates/Audit Checks/AUDIT CHECKS OF COMPOUND WALL WORKS.docx",
        download2: null,
      },
      {
        id: 3,
        name: "AUDIT CHECKS OF PIPE LINE WORKS",
        view: "/Templates/Templates/Audit Checks/AUDIT CHECKS OF PIPE LINE WORKS.pdf",
        download1:
          "/Templates/Templates/Audit Checks/AUDIT CHECKS OF PIPE LINE WORKS.docx",
        download2: null,
      },
      {
        id: 4,
        name: "AUDIT CHECKS OF SIDE DRAINS",
        view: "/Templates/Templates/Audit Checks/AUDIT CHECKS OF SIDE DRAINS.pdf",
        download1:
          "/Templates/Templates/Audit Checks/AUDIT CHECKS OF SIDE DRAINS.docx",
        download2: null,
      },
      {
        id: 5,
        name: "AUDIT CHECKS OF SILT CLEARENCE WORKS",
        view: "/Templates/Templates/Audit Checks/AUDIT CHECKS OF SILT CLEARENCE WORKS.pdf",
        download1:
          "/Templates/Templates/Audit Checks/AUDIT CHECKS OF SILT CLEARENCE WORKS.docx",
        download2: null,
      },
      {
        id: 6,
        name: "AUDIT CHECKS OF SILT CLEARENCE WORKS",
        view: null,
        download1: "/Templates/Templates/Audit Checks/SC_ Empty Format.docx",
        download2: null,
      },
    ],
  },
  {
    id: 22,
    tableId: 1,
    title: "Surcharge Proceedings",
    rows: [
      {
        id: 1,
        name: "SC Format",
        view: null,
        download1: "/Templates/Templates/Audit Checks/SC_ Empty Format.docx",
        download2: null,
      },
    ],
  },
  {
    id: 12,
    tableId: 2,
    title: "Administrative Sanction",
    rows: [{ id: 1, name: "Core test", view: "/Sand.pdf" }],
  },
  {
    id: 13,
    tableId: 2,
    title: "Third Party Quality Control(Strength)",
    rows: [{ id: 1, name: "Core test", view: "/Sand.pdf" }],
  },

  {
    id: 51,
    tableId: 1,
    title: "State Audit",
    rows: [
      {
        id: 1,
        name: "DSA site",
        view: "http://www.dsa.ap.gov.in/",
      },
      {
        id: 2,
        name: "State Audit Act",
        view: "https://www.indiacode.nic.in/bitstream/123456789/16190/1/9_of_1989.pdf",
      },
      {
        id: 3,
        name: "AP State Audit Rules",
        view: "http://www.bareactslive.com/AP/ap463.htm",
      },
    ],
  },
  {
    id: 52,
    tableId: 1,
    title: "CAG",
    rows: [
      {
        id: 1,
        name: "Reports",
        view: "https://cag.gov.in/ag/andhra-pradesh/en/audit-report?ts=allwords&title=&gt=51&udt=&state=&state%5B%5D=64&lbt=&sector=&report_type=&od=&yrf=&yrt=",
      },
      {
        id: 2,
        name: "Style guide",
        view: "https://cag.gov.in/uploads/media/Style-Guide-for-use-in-IA-AD-20200624144014.pdf",
      },
      {
        id: 3,
        name: "Guidelines",
        view: "https://cag.gov.in/uploads/media/PRI-Manual-20201127173825.pdf,",
      },
    ],
  },
  {
    id: 53,
    tableId: 1,
    title: "Government Orders",
    rows: [],
  },
  {
    id: 54,
    tableId: 1,
    title: "Pay and establishment related",
    rows: [
      {
        id: 1,
        name: "CFMS login",
        view: "https://prdcfms.apcfss.in:44300/sap/bc/ui5_ui5/ui2/ushell/shells/abap/FioriLaunchpad.html?sap-client=350%20",
      },
      {
        id: 2,
        name: "Payroll.herb",
        view: "https://nidhi.apcfss.in/login",
      },
      {
        id: 3,
        name: "GPF slip",
        view: "https://ag.ap.nic.in/SlipsGpf.aspx",
      },
    ],
  },

  {
    id: 21,
    tableId: 1,
    title: "AAS SA's",
    rows: [
      {
        id: 1,
        name: "AAS SA's SGP",
        view: "/Templates/Templates/AAS SA's/AAS SA's SGP.pdf",
        download1: "/Templates/Templates/AAS SA's/AAS SA's SGP.docx",
        download2: null,
      },
      {
        id: 2,
        name: "AAS SA's SPP IA",
        view: "/Templates/Templates/AAS SA's/AAS SA's SPP IA.pdf",
        download1: "/Templates/Templates/AAS SA's/AAS SA's SPP IA.docx",
        download2: null,
      },
      {
        id: 3,
        name: "AAS SA's SPP IB",
        view: "/Templates/Templates/AAS SA's/AAS SA's SPP IB.pdf",
        download1: "/Templates/Templates/AAS SA's/AAS SA's SPP IB.docx",
        download2: null,
      },
      {
        id: 4,
        name: "AAS SA's SPP IIA",
        view: "/Templates/Templates/AAS SA's/AAS SA's SPP IIA.pdf",
        download1: "/Templates/Templates/AAS SA's/AAS SA's SPP IIA.docx",
        download2: null,
      },
    ],
  },
  {
    id: 21,
    tableId: 1,
    title: "ACRs and Record Sheets",
    rows: [
      {
        id: 1,
        name: "ACRs",
        view: null,
        download1:
          "/Templates/Templates/ACRs and Record Sheets templates/ACRs templates.doc",
        download2: null,
      },
      {
        id: 2,
        name: "Character Rolls",
        view: null,
        download1:
          "/Templates/Templates/ACRs and Record Sheets templates/Character Rolls template.doc",
        download2: null,
      },
      {
        id: 3,
        name: "record sheets modified",
        view: null,
        download1:
          "/Templates/Templates/ACRs and Record Sheets templates/record sheets modified template.doc",
        download2: null,
      },
      {
        id: 3,
        name: "CR for guidence",
        view: "/Templates/Templates/ACRs and Record Sheets templates/CR for guidence.pdf",
        download1: null,
        download2: null,
      },
    ],
  },
  {
    id: 21,
    tableId: 1,
    title: "APGLI",
    rows: [
      {
        id: 1,
        name: "Affidavit (Insurance)",
        view: "/Templates/Templates/APGLI/Affidavit.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 2,
        name: "Death Certificate(Insurance)",
        view: "/Templates/Templates/APGLI/DeathCertificate.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 3,
        name: "Departmental Information Form",
        view: "/Templates/Templates/APGLI/DepartmentalInformationForm.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 4,
        name: "GO (for enhancement upto 20%)",
        view: "/Templates/Templates/APGLI/GO (for enhancement upto 20%).pdf",
        download1: null,
        download2: null,
      },
      {
        id: 5,
        name: "GOOD HEALTH CERTIFICATE",
        view: "/Templates/Templates/APGLI/GOOD HEALTH CERTIFICATE.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 6,
        name: "Guardianship Declaration Certificate",
        view: "/Templates/Templates/APGLI/Guardianship Declaration Certificate.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 7,
        name: "Indemnity Bond",
        view: "/Templates/Templates/APGLI/Indemnity Bond.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 8,
        name: "Loan Application Form",
        view: "/Templates/Templates/APGLI/Loan Application Form.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 9,
        name: "MEDICAL LEAVE CERTIFICATE",
        view: "/Templates/Templates/APGLI/MEDICAL LEAVE CERTIFICATE.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 10,
        name: "Nominee Form",
        view: "/Templates/Templates/APGLI/Nominee Form.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 11,
        name: "Proposal Form",
        view: "/Templates/Templates/APGLI/Proposal Form.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 12,
        name: "Refund Form(Death Claim)",
        view: "/Templates/Templates/APGLI/Refund Form(Death Claim).pdf",
        download1: null,
        download2: null,
      },
      {
        id: 13,
        name: "Refund Form(other than death claim)",
        view: "/Templates/Templates/APGLI/Refund Form(other than death claim).pdf",
        download1: null,
        download2: null,
      },
    ],
  },
  {
    id: 21,
    tableId: 1,
    title: "Appointment Orders of DR SAs and AAOS",
    rows: [
      {
        id: 1,
        name: "DR SAs appointment orders",
        view: null,
        download1:
          "/Templates/Templates/Appointment Orders of DR SAs and AAOS/DR SAs appointment orders template.docx",
        download2: null,
      },
      {
        id: 2,
        name: "DR SAs appointment orders",
        view: null,
        download1:
          "/Templates/Templates/Appointment Orders of DR SAs and AAOS/DR SAs appointment orders template.docx",
        download2: null,
      },
      {
        id: 3,
        name: "Promotion orders of JAs and Typists",
        view: null,
        download1:
          "/Templates/Templates/Appointment Orders of DR SAs and AAOS/Promotion orders of JAs and Typists template.docx",
        download2: null,
      },
      {
        id: 4,
        name: "promotion orders of SAs",
        view: null,
        download1:
          "/Templates/Templates/Appointment Orders of DR SAs and AAOS/promotion orders of SAs template.doc",
        download2: null,
      },
    ],
  },
  {
    id: 21,
    tableId: 1,
    title: "Commencement and Declaration of Probation of SAs",
    rows: [
      {
        id: 1,
        name: "B.Com exemption",
        view: null,
        download1:
          "/Templates/Templates/Commencement and Declaration of Probation of SAs procgs. templates/B.Com exemption procgs. template.docx",
        download2: null,
      },
      {
        id: 2,
        name: "Commencement of Probation DR Senior Auditors",
        view: null,
        download1:
          "/Templates/Templates/Commencement and Declaration of Probation of SAs procgs. templates/Commencement of Probation DR Senior Auditors.docx",
        download2: null,
      },
      {
        id: 3,
        name: "Commencement of Probation of Promotee SAs Procgs",
        view: null,
        download1:
          "/Templates/Templates/Commencement and Declaration of Probation of SAs procgs. templates/Commencement of Probation of Promotee SAs Procgs. template.docx",
        download2: null,
      },
      {
        id: 4,
        name: "Declaration of Probation of DR SAs Procgs",
        view: null,
        download1:
          "/Templates/Templates/Commencement and Declaration of Probation of SAs procgs. templates/Declaration of Probation of DR SAs Procgs. template.docx",
        download2: null,
      },
      {
        id: 5,
        name: "Declaration of Probation of Promotee SAs Procgs",
        view: null,
        download1:
          "/Templates/Templates/Commencement and Declaration of Probation of SAs procgs. templates/Declaration of Probation of Promotee SAs Procgs. template.docx",
        download2: null,
      },
      {
        id: 6,
        name: "Telugu Language exemption orders",
        view: null,
        download1:
          "/Templates/Templates/Commencement and Declaration of Probation of SAs procgs. templates/Telugu Language exemption orders template.docx",
        download2: null,
      },
    ],
  },
  {
    id: 21,
    tableId: 1,
    title: "GPF",
    rows: [
      {
        id: 1,
        name: "Final Withdrawal application form",
        view: "/Templates/Templates/GPF/Final Withdrawal application form.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 2,
        name: "Partfinal withdrawal application form",
        view: "/Templates/Templates/GPF/Partfinal withdrawal application form.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 3,
        name: "Temporary Advance application form",
        view: "/Templates/Templates/GPF/Temporary Advance application form.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 4,
        name: "GPF - Part Final",
        view: null,
        download1: "/Templates/Templates/GPF/GPF - Part Final.docx",
        download2: null,
      },
      {
        id: 5,
        name: "GPF - Part Final",
        view: null,
        download1: "/Templates/Templates/GPF/GPF-Temp. Adv..doc",
        download2: null,
      },
    ],
  },
  {
    id: 21,
    tableId: 1,
    title: "Income tax templates",
    rows: [
      {
        id: 1,
        name: "FORM 16A-",
        view: "/Templates/Templates/Income tax templates/FORM 16A-.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 2,
        name: "IT calculation",
        view: null,
        download1: null,
        download2:
          "/Templates/Templates/Income tax templates/IT calculation.xlsx",
      },
    ],
  },
  {
    id: 21,
    tableId: 1,
    title: "leave procdgs. of AAOs",
    rows: [
      {
        id: 1,
        name: "Child care Leave of AAOs - Procds",
        view: null,
        download1:
          "/Templates/Templates/leave procdgs. of AAOs/Child care Leave of AAOs - Procds. template.docx",
        download2: null,
      },
      {
        id: 2,
        name: "Commutted Leave Procds",
        view: null,
        download1:
          "/Templates/Templates/leave procdgs. of AAOs/Commutted Leave Procds. template.docx",
        download2: null,
      },
      {
        id: 3,
        name: "EL & HPL on MC Procdgs - Copy",
        view: null,
        download1:
          "/Templates/Templates/leave procdgs. of AAOs/EL & HPL on MC Procdgs. template - Copy.docx",
        download2: null,
      },
      {
        id: 4,
        name: "EL & HPL on private affairs - Procdgs",
        view: null,
        download1:
          "/Templates/Templates/leave procdgs. of AAOs/EL & HPL on private affairs - Procdgs. template.docx",
        download2: null,
      },
      {
        id: 5,
        name: "EOL procgs",
        view: null,
        download1:
          "/Templates/Templates/leave procdgs. of AAOs/EOL procgs. template.docx",
        download2: null,
      },
      {
        id: 6,
        name: "Maternity Leave Procgs. of SAs",
        view: null,
        download1:
          "/Templates/Templates/leave procdgs. of AAOs/Maternity Leave Procgs. of SAs template.docx",
        download2: null,
      },
      {
        id: 7,
        name: "Paternity Leave of AAOs (Autosaved)",
        view: null,
        download1:
          "/Templates/Templates/leave procdgs. of AAOs/Paternity Leave of AAOs (Autosaved).docx",
        download2: null,
      },
      {
        id: 8,
        name: "SEL procdgs for AAOs",
        view: null,
        download1:
          "/Templates/Templates/leave procdgs. of AAOs/SEL procdgs. template for AAOs.docx",
        download2: null,
      },
    ],
  },
  {
    id: 21,
    tableId: 1,
    title: "leave procds. of SAs",
    rows: [
      {
        id: 1,
        name: "Child Care Leave Procgs. of SAs",
        view: null,
        download1:
          "/Templates/Templates/leave procds. of SAs/Child Care Leave Procgs. of SAs template.docx",
        download2: null,
      },
      {
        id: 2,
        name: "Commutted Leave Procgs. of SAs",
        view: null,
        download1:
          "/Templates/Templates/leave procds. of SAs/Commutted Leave Procgs. of SAs template.docx",
        download2: null,
      },
      {
        id: 3,
        name: "EL and HPL Procgs. of SAs",
        view: null,
        download1:
          "/Templates/Templates/leave procds. of SAs/EL and HPL Procgs. of SAsTemplate.docx",
        download2: null,
      },
      {
        id: 4,
        name: "EOL Procgs. of SAs ",
        view: null,
        download1:
          "/Templates/Templates/leave procds. of SAs/EOL Procgs. of SAs Template.docx",
        download2: null,
      },
      {
        id: 5,
        name: "Maternity Leave Procgs. of SAs",
        view: null,
        download1:
          "/Templates/Templates/leave procds. of SAs/Maternity Leave Procgs. of SAs template.docx",
        download2: null,
      },
      {
        id: 6,
        name: "Paternity Leave Procgs. of SAs",
        view: null,
        download1:
          "/Templates/Templates/leave procds. of SAs/Paternity Leave Procgs. of SAs template.docx",
        download2: null,
      },
      {
        id: 7,
        name: "SEL procdgs for SAs",
        view: null,
        download1:
          "/Templates/Templates/leave procds. of SAs/SEL procdgs. template for SAs.docx",
        download2: null,
      },
    ],
  },
  {
    id: 21,
    tableId: 1,
    title: "others",
    rows: [
      {
        id: 1,
        name: "45 years crossed-exemption orders ",
        view: null,
        download1:
          "/Templates/Templates/others/45 years crossed-exemption orders template.doc",
        download2: null,
      },
      {
        id: 2,
        name: "6,12,18,24,30 years proposals ",
        view: null,
        download1:
          "/Templates/Templates/others/6,12,18,24,30 years proposals template.doc",
        download2: null,
      },
      {
        id: 3,
        name: "Annexure to DR - Oath of Allegiance etc.",
        view: null,
        download1:
          "/Templates/Templates/others/Annexures to DR - Oath of Allegiance etc.doc",
        download2: null,
      },
      {
        id: 4,
        name: "CL application ",
        view: null,
        download1: "/Templates/Templates/others/CL application template.docx",
        download2: null,
      },
      {
        id: 5,
        name: "Compassionate Appointment order",
        view: null,
        download1:
          "/Templates/Templates/others/Compassionate Appointment order.doc",
        download2: null,
      },
      {
        id: 6,
        name: "Increment Certificate",
        view: null,
        download1: "/Templates/Templates/others/Increment Certificate.doc",
        download2: null,
      },
      {
        id: 7,
        name: "leave eligibility certificate",
        view: null,
        download1:
          "/Templates/Templates/others/leave eligibility certificate.doc",
        download2: null,
      },
      {
        id: 8,
        name: "New Microsoft Office Word Document",
        view: null,
        download1:
          "/Templates/Templates/others/New Microsoft Office Word Document.docx",
        download2: null,
      },
      {
        id: 9,
        name: "OH application",
        view: null,
        download1: "/Templates/Templates/others/OH application template.docx",
        download2: null,
      },
      {
        id: 10,
        name: "PASSPORT NOC proforma",
        view: null,
        download1: "/Templates/Templates/others/PASSPORT NOC proforma.doc",
        download2: null,
      },
      {
        id: 11,
        name: "Physical Fitness",
        view: null,
        download1: "/Templates/Templates/others/Physical Fitness.doc",
        download2: null,
      },
      {
        id: 12,
        name: "Proforma for acquisition of property",
        view: "/Templates/Templates/others/filled - Proforma for acquisition of property.pdf",
        download1:
          "/Templates/Templates/others/Proforma for acquisition of property-dummy.doc",
        download2: null,
      },
      {
        id: 13,
        name: "Proformae to visit abroad",
        view: "/Templates/Templates/Templates/others/Proformae to visit abroad.pdf",
        download1: null,
        download2: null,
      },
      {
        id: 14,
        name: "Property Statements -Annexure I & II",
        view: null,
        download1:
          "/Templates/Templates/others/Property Statements -Annexure I & II.doc",
        download2: null,
      },
      {
        id: 15,
        name: "SL application",
        view: null,
        download1: "/Templates/Templates/others/SL application template.docx",
        download2: null,
      },
      {
        id: 16,
        name: "Verification of Antecedents",
        view: null,
        download1:
          "/Templates/Templates/others/Verification of Antecedents templates.doc",
        download2: null,
      },
      {
        id: 17,
        name: "Property Statements -Annexure I & II filled",
        view: null,
        download1:
          "/Templates/Templates/others/Property Statements -Annexure I & II filled.pdf",
        download2: null,
      },
    ],
  },
  {
    id: 21,
    tableId: 1,
    title: "pay fixation of AAOs",
    rows: [
      {
        id: 1,
        name: "Pay Fixation of AAOs (FR 22 B from the dt. of increment)",
        view: "/Templates/Templates/pay fixation of AAOs (templates)/Pay Fixation of AAOs (FR 22 B from the dt. of increment).pdf",
        download1:
          "/Templates/Templates/pay fixation of AAOs (templates)/Pay Fixation of AAOs (FR 22 B from the dt. of increment).doc",
        download2: null,
      },
      {
        id: 2,
        name: "Pay Fixation of AAOs (FR 22 B from the dt. of promotion)",
        view: "/Templates/Templates/pay fixation of AAOs (templates)/Pay Fixation of AAOs (FR 22 B from the dt. of promotion).pdf",
        download1:
          "/Templates/Templates/pay fixation of AAOs (templates)/Pay Fixation of AAOs (FR 22 B from the dt. of promotion).doc",
        download2: null,
      },
      {
        id: 3,
        name: "Pay Fixation of AAOs -(initially under FR 22(a)(i) and later FR 22-B)",
        view: "/Templates/Templates/pay fixation of AAOs (templates)/Pay Fixation of AAOs -(initially under FR 22(a)(i) and later FR 22-B).pdf",
        download1:
          "/Templates/Templates/pay fixation of AAOs (templates)/Pay Fixation of AAOs -(initially under FR 22(a)(i) and later FR 22-B).doc",
        download2: null,
      },
    ],
  },
  {
    id: 21,
    tableId: 1,
    title: "pay fixation of SAs",
    rows: [
      {
        id: 1,
        name: "Pay Fixation of SAs (FR 22 B from the dt. of increment)",
        view: "/Templates/Templates/pay fixation of SAs (templates)/Pay Fixation of SAs (FR 22 B from the dt. of increment).pdf",
        download1:
          "/Templates/Templates/pay fixation of SAs (templates)/Pay Fixation of SAs (FR 22 B from the dt. of increment).doc",
        download2: null,
      },
      {
        id: 2,
        name: "Pay Fixation of SAs (FR 22 B from the dt. of promotion)",
        view: "/Templates/Templates/pay fixation of SAs (templates)/Pay Fixation of SAs (FR 22 B from the dt. of promotion).pdf",
        download1:
          "/Templates/Templates/pay fixation of SAs (templates)/Pay Fixation of SAs (FR 22 B from the dt. of promotion).doc",
        download2: null,
      },
      {
        id: 3,
        name: "Pay Fixation of SAs -(initially under FR 22(a)(i) and later FR 22-B)",
        view: "/Templates/Templates/pay fixation of SAs (templates)\\Pay Fixation of SAs -(initially under FR 22(a)(i) and later FR 22-B).pdf",
        download1:
          "/Templates/Templates/pay fixation of SAs (templates)\\Pay Fixation of SAs -(initially under FR 22(a)(i) and later FR 22-B).doc",
        download2: null,
      },
    ],
  },
  {
    id: 21,
    tableId: 1,
    title: "Proformae for probation",
    rows: [
      {
        id: 1,
        name: "Probation Proforma for AAOs",
        view: null,
        download1:
          "/Templates/Templates/Proformae for probation/Probation Proforma for AAOs.doc",
        download2: null,
      },
      {
        id: 2,
        name: "Probation Proforma for SAs",
        view: null,
        download1:
          "/Templates/Templates/Proformae for probation/Probation Proforma for SAs.doc",
        download2: null,
      },
    ],
  },
  {
    id: 55,
    tableId: 1,
    title: "Auditee institutions websites",
    rows: [
      {
        id: 1,
        name: "Agriculture & Marketing",
        view: "https://vyavasayamarketingshakha.ap.gov.in/agriMrkt/Dashboard/index.html",
      },
      {
        id: 2,
        name: "HR & CEIs",
        view: "https://www.aptemples.ap.gov.in/en-in/home",
      },
      {
        id: 3,
        name: "Taxes & Fees",
        view: "https://cpr.ap.gov.in/EODBReforms.jsp",
      },
    ],
  },
  {
    id: 56,
    tableId: 1,
    title: "Works Audit",
    rows: [
      {
        id: 1,
        name: "APDSS & other codes",
        view: "https://www.randb.ap.gov.in/Actsrules",
      },
      {
        id: 2,
        name: "CPWD manual",
        view: "https://cpwd.gov.in/Publication/SOP_for_CPWD_Works_Manual_2022_13122022.pdf",
      },
      {
        id: 3,
        name: "AP Works audit manual",
        view: "https://ag.ap.nic.in/agers/MANUAL%20OF%20WORKS%20AUDIT.pdf",
      },
    ],
  },
  {
    id: 57,
    tableId: 1,
    title: "Drivers & Software",
    rows: [
      {
        id: 1,
        name: "Biometric device drivers",
        view: "https://aadhar.apcfss.in/jsp/report/aadhar_installation_guide.jsp",
      },
      {
        id: 2,
        name: "Telugu typing",
        view: "https://www.microsoft.com/en-in/bhashaindia/downloads.aspx",
      },
    ],
  },
  {
    id: 71,
    tableId: 1,
    title: "Para Bank",
    rows: [
      {
        id: 1,
        name: "Parabank",
        view: "/Para Bank Final.pdf",
        download1: "/Para Bank Final.docx",
        download2: null,
      },
    ],
  },
];
const handleDownload = (url, e) => {
  e.preventDefault(); // Prevent the default anchor tag behavior

  // Assuming 'url' is a path to a valid resource, trigger the download
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // Explicitly setting the MIME type for the Blob for a .docx file
      const fileType =
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      const newBlob = new Blob([blob], { type: fileType });

      // Create a new URL pointing to the newBlob
      const blobUrl = window.URL.createObjectURL(newBlob);

      // Create a link and set the URL and download attribute
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = url.split("/").pop(); // Suggest a filename to save as, taking the last part of the URL

      // Append link to the body, click it, and then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => console.error("Download error:", error));
};
const getRandomColor = () => {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 8)];
  }
  return color;
};
const TableGrid = () => {
  // Function to handle PDF download
  //const { title } = useParams();
  const location = useLocation();
  const id = location.state.subId;
  console.log("cid" + id);
  console.log("subId" + location.state.subId);

  // Filter the jsonData based on the cid captured
  const filteredData = tablesData.filter((card) => card.id === id);
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={5}>
        {filteredData.map((table) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            key={table.tableId}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <TableContainer
              component={Paper}
              elevation={12}
              sx={{
                maxHeight: 400,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
              }}
            >
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={4}
                      sx={{
                        background: getRandomColor(),
                        color: "white",
                        fontWeight: "bold",
                        tableLayout: "auto",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {table.title}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold", color: getRandomColor() }}
                    >
                      ID
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", color: getRandomColor() }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", color: getRandomColor() }}
                    >
                      View
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", color: getRandomColor() }}
                    >
                      Download
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table.rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {row.view && (
                          <PdfViewerPopup
                            pdfUrl={`${process.env.PUBLIC_URL}${row.view}`}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {row.download1 && (
                          <a
                            href={row.download1}
                            onClick={(e) => handleDownload(row.download1, e)}
                          >
                            <GetAppRoundedIcon color="primary" />
                          </a>
                        )}
                        {row.download2 && (
                          <a
                            href={row.download2}
                            onClick={(e) => handleDownload(row.download2, e)}
                          >
                            <GetAppRoundedIcon color="inherit" />
                          </a>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TableGrid;
