// ThirdPage.js
import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import TableGrid from "./thirpages"; // ensure path is correct
import { CssBaseline, Container } from "@mui/material";
import { useParams } from "react-router-dom";

const MainContainer = styled("div")({
  border: "22px solid #0766AD",
  borderRadius: "7px", // Adjusted the border for simplicity
  padding: "1px",
  marginBlock: "2",
  display: "-ms-flexbox",
  boxSizing: "border-box",
  height: 840,
  background: "#CDF5FD",
  overflow: "auto",
});
const ThirdPage = () => {
  //id,
  const { title } = useParams();

  return (
    <MainContainer>
      <h1 style={{ color: "#0766AD", textAlign: "center" }}>{title}</h1>
      <React.Fragment>
        <CssBaseline /> {/* Normalize styling */}
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            align="center"
            style={{ color: "#0766AD" }}
          ></Typography>
          <TableGrid />
        </Container>
      </React.Fragment>
    </MainContainer>
  );
};

export default ThirdPage;
