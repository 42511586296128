import React from 'react';
import { Breadcrumbs, Link, Typography, useTheme } from '@mui/material';
import { useLocation, NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const MyBreadcrumbs = () => {
  const theme = useTheme();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  // Function to format breadcrumb text
  const formatText = (text) => {
    // Replace dashes with spaces and capitalize
    return text.replace(/-/g, ' ').charAt(0).toUpperCase() + text.slice(1);
  };

  return (
    <Breadcrumbs  sx={{ color: '#9AD0C2' }}>
      {pathnames.length > 0 ? (
        <Link component={NavLink} to="/" sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Link>
      ) : (
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Home
        </Typography>
      )}
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        const text = formatText(value);

        return last ? (
          <Typography key={to} color="white">
            <div style={{flex: 1, flexDirection: "row", display: "flex"}}>
            <KeyboardDoubleArrowRightIcon/><div>{text}</div>
            </div>
          </Typography>
        ) : (
          <Link component={NavLink} to={to} key={to} state={{"cid":location.state.cid}} sx={{ color: '#9AD0C2' }}>
             <div style={{flex: 1, flexDirection: "row", display: "flex"}}>
            <KeyboardDoubleArrowRightIcon/><div>{text}</div>
            </div>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default MyBreadcrumbs;
