import React, { useState } from 'react';
import { Box,Paper, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextareaAutosize } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CloseIcon from '@mui/icons-material/Close';

export default function FeedbackButton() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    console.log({
      fullName: formData.get('fullName'),
      email: formData.get('email'),
      phoneNumber: formData.get('phoneNumber'),
      suggestion: formData.get('suggestion'),
    });
    // Submit your form data to your backend here
    handleClose(); // Close the form upon submission
  };

  return (
    <>
      <Box sx={{ position: 'fixed', right: 16, top: '90%', zIndex: 800, transform: 'translateY(-50%) rotate(-90deg)' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          sx={{ borderRadius: 1, padding: '6px 16px', transform: 'rotate(360deg)' }} // Rectangular with slight rounding and rotated
        >
          Feedback
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" sx={{borderRadius:"18px"}}>
        <Paper elevation={12} sx={{borderRadius:"16px",background:"#EEF5FF",border:'4px solid #176B87'}}>
        <DialogTitle>
          Feedback Form
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%' }}>
            <TextField
              autoFocus
              margin="dense"
              id="fullName"
              name="fullName"
              label="Full Name"
              type="text"
              fullWidth
              variant="outlined"
            />
            <TextField
              margin="dense"
              id="email"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
            />
            <TextField
              margin="dense"
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              type="text"
              fullWidth
              variant="outlined"
            />
            <TextareaAutosize
              minRows={3}
              placeholder="Your valuable suggestions"
              style={{ width: '100%', marginTop: '16px' }}
              name="suggestion"
              variant="outlined"
            />
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Submit</Button>
            </DialogActions>
          </Box>
        </DialogContent>
        </Paper>
      </Dialog>
    </>
  );
}
