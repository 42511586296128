import React from "react";
import { Box, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";

const NoticeBoard = ({ notices }) => {
  const ScrollingText = ({ sx, children }) => (
    <Box
      sx={{
        ...sx,
        whiteSpace: "nowrap", // Ensure the text stays in a single line
        overflow: "hidden", // Hide the overflow
        display: "flex", // Align child elements in a row
        alignItems: "center", // Center items vertically
      }}
    >
      {children}
    </Box>
  );
  return (
    <Box
      sx={{
        width: "100%",
        border: "solid",
        borderColor: "#0766AD",
        borderRadius: 2,
        overflow: "-moz-hidden-unscrollable",
      }}
    >
      {/* <ScrollingText
        sx={{
          animation: "scroll 25s linear infinite",
          "@keyframes scroll": {
            "0%": { transform: "translateX(100%)" },
            "10%, 50%": { transform: "translateX(1)" }, // Pause when fully visible
            "100%": { transform: "translateX(-100% -100px)" }, // Ends just off the left edge of the box
          },
        }}
      > */}
      <Marquee>
        {notices.map((notice, index) => (
          <Typography
            key={notice.id}
            component="span"
            variant="h6"
            sx={{ pr: 0, fontFamily: "-moz-initial" }}
          >
            {notice.text}
          </Typography>
        ))}
      </Marquee>

      {/* </ScrollingText> */}
    </Box>
  );
};

export default function App() {
  const initialNotices = [{ id: 1, text: "Welcome to Audit Insights" }];

  return (
    <Box sx={{ m: 1 }}>
      <NoticeBoard notices={initialNotices} />
    </Box>
  );
}
