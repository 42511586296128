import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Dialog,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Your JSON data
const jsonData = {
  StateAudit: {
    id: 1,
    DSASite: {
      id: 2,
      url: "http://www.dsa.ap.gov.in/",
    },
    StateAuditAct: {
      id: 3,
      title: "Act 9 of 1989",
    },
    APStateAuditRules: {
      id: 4,
      url: "http://www.bareactslive.com/AP/ap463.htm",
    },
  },
  CAG: {
    id: 5,
    Reports: {
      id: 6,
      title: "CAG Local Body Reports AP",
    },
    StyleGuide: {
      id: 7,
      title: "Styleguide",
    },
    Guidelines: [
      {
        id: 8,
        title: "PRI Audit Manual",
      },
      {
        id: 9,
        title: "Guidelines-for-Financial-Audit-of-PRIs",
      },
    ],
  },
  GovernmentOrders: [
    {
      id: 10,
      url: "https://apegazette.cgg.gov.in/homeEgazetteSearch.do",
    },
    {
      id: 11,
      url: "https://goir.ap.gov.in/",
    },
  ],
  PayAndEstablishmentRelated: {
    id: 12,
    CFMSLogin: {
      id: 13,
      title: "CFMS Login",
    },
    PRC: {
      id: 14,
    },
    PayrollHerb: {
      id: 15,
      title: "Herb",
    },
    GPFSlip: {
      id: 16,
      types: [
        {
          id: 17,
          type: "Regular",
        },
        {
          id: 18,
          type: "Class IV",
        },
      ],
    },
    CPS: {
      id: 19,
      title: "NPS Login",
    },
    APGLIC: {
      id: 20,
      title: "APGLI",
    },
    EmployeeHealthScheme: {
      id: 21,
      title: "EHS",
    },
    APPSC: {
      id: 22,
      title: "APPSC",
    },
    AnyResults: {
      id: 23,
      title: "Manabadi",
    },
    APFinancialCode: {
      id: 24,
      url: "https://www.randb.ap.gov.in/Actsrules",
    },
    TreasuryCode: {
      id: 25,
      url: "https://www.randb.ap.gov.in/Actsrules",
    },
    PensionRulesPFrulesEtc: {
      id: 26,
      url: "https://www.randb.ap.gov.in/Actsrules#parentHorizontalTab6",
    },
    IncomeTax: {
      id: 27,
      Filing: {
        id: 28,
        title: "IT e-filing",
      },
      Details: [
        {
          id: 29,
          title: "TDS details",
        },
        {
          id: 30,
          title: "26Q",
        },
        {
          id: 31,
          title: "26AS",
        },
        {
          id: 32,
          title: "Form 16 -TDS",
        },
      ],
    },
    MyAadhaar: {
      id: 33,
      url: "https://myaadhaar.uidai.gov.in/",
    },
  },
  AuditeeInstitutionsWebsites: {
    id: 34,
    AgricultureAndMarketing: {
      id: 35,
      DepartmentWebsite: "Agriculture Marketing Dept",
      Act: {
        id: 36,
        title: "Act 16 of 1966",
      },
      Rules: {
        id: 37,
        title: "AP Market Rules 1969",
      },
    },
    HRAndCEIs: {
      id: 38,
      DepartmentWebsite: "Dept website",
      Act: {
        id: 39,
        title: "Act 30 of 1987",
      },
      VariousRules: {
        id: 40,
        title: "Rules",
      },
      FASPreaudit: {
        id: 41,
        title: "fasendowments",
      },
      TTD: {
        id: 42,
        url: "https://www.tirumala.org/",
      },
    },
    // ... Continue with the rest of the departments similar to above entries
  },
  WorksAudit: {
    id: 43,
    APDSSAndOtherCodes: {
      id: 44,
      url: "https://www.randb.ap.gov.in/Actsrules",
    },
    CPWDManual: {
      id: 45,
      title: "CPWD manual",
    },
    APWorksAuditManual: {
      id: 46,
      title: "Works audit manual",
    },
    APRevisedStdData: {
      id: 47,
      RoadsAndBridges: {
        id: 48,
        title: "Roads & Bridges, MORTH Excel sheet",
      },
      Buildings: {
        id: 49,
        title: "AP Buildings data",
      },
      PH: {
        id: 50,
        title: "PH data",
      },
      CPWDData: {
        id: 51,
        title: "CPWD data",
      },
      SSR: {
        id: 52,
        title: "SSR",
      },
    },
  },
  DriversAndSoftware: {
    id: 53,
    BiometricDeviceDrivers: {
      id: 54,
      title: "Biometric drivers",
    },
    TeluguTyping: {
      id: 55,
      title: "Microsoft Indic",
    },
  },
};
const MainContainer = styled("div")({
  border: "22px solid #0766AD",
  borderRadius: "7px", // Adjusted the border for simplicity
  padding: "1px",
  marginBlock: "6",
  display: "flex",
  boxSizing: "border-box",
  width: "100% flex",
  background: "#CDF5FD",
});
// Function to generate random color
// Function to generate random color
const getRandomColor = () => {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

function DataDisplayTable() {
  const [open, setOpen] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState("");

  const handleClickOpen = (url) => {
    setSelectedUrl(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Flatten the JSON data and extract URLs
  const dataEntries = Object.entries(jsonData).flatMap(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      return Object.entries(value).map(([subKey, subValue]) => {
        return { sectionTitle: subKey, ...subValue };
      });
    }
    return [{ sectionTitle: key, ...value }];
  });

  return (
    <MainContainer>
      <Box sx={{ flexGrow: 1, padding: 4 }}>
        <h2>Usefull Links</h2>
        <Grid container spacing={1}>
          {dataEntries.map((item, index) => (
            <Grid item xs={1} key={index}>
              {" "}
              {/* xs={1} for 12 items in a row */}
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  background:
                    "linear-gradient(135deg, #252c42 10%, #3a39c4 100%);",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  border: `2px solid ${getRandomColor()}`,
                }}
              >
                <CardContent>
                  <Typography
                    variant="p"
                    component="div"
                    style={{
                      width: "100%",
                      wordWrap: "break-word",
                      fontWeight: "bold",
                      color: "#FFF",
                      textAlign: "center",
                    }}
                  >
                    {item.sectionTitle}
                  </Typography>
                </CardContent>
                {item.url && (
                  <Box sx={{ margin: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleClickOpen(item.url)}
                    >
                      Open Link
                    </Button>
                  </Box>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
        {/* Dialog for Iframe */}
        <Dialog fullScreen open={open} onClose={handleClose}>
          <iframe
            src={selectedUrl}
            title="content"
            style={{ width: "100%", height: "100%" }}
          />
        </Dialog>
      </Box>
    </MainContainer>
  );
}

export default DataDisplayTable;
