import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Card,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// Utility function to extract YouTube video ID from URL
const extractVideoID = (url) => {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

// Convert normal YouTube link to an embed link
const getEmbedVideoUrl = (url) => {
  const videoId = extractVideoID(url);
  return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
};

const sampleVideos = [
  {
    id: "21212",
    title: "A",
    youtubeLink: "https://youtu.be/YKm4qQTqh5g",
  },
  {
    id: "12345",
    title: "B",
    youtubeLink: "https://youtu.be/zsmEgg7bffM",
  },
  {
    id: "21212",
    title: "C",
    youtubeLink: "https://youtu.be/fnVhZtiaQHs",
  },
  {
    id: "21213",
    title: "D",
    youtubeLink: "https://youtu.be/QHua4nofXjE",
  },

  // ... more video objects with unique ids and YouTube links
];
const getRandomColor = () => {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 8)];
  }
  return color;
};
const getRandomLightColor = () => {
  const hue = Math.floor(Math.random() * 360);
  const saturation = Math.floor(Math.random() * (100 - 50) + 50); // Between 50-100%
  const lightness = Math.floor(Math.random() * (100 - 70) + 70); // Between 70-100%
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

const MainContainer = styled("div")({
  border: "22px solid #0766AD",
  borderRadius: "7px", // Adjusted the border for simplicity
  padding: "1px",
  marginBlock: "2",
  display: "-ms-flexbox",
  boxSizing: "border-box",
  height: 840,
  background: "#CDF5FD",
});
const YouTubeClone = () => {
  const [selectedVideo, setSelectedVideo] = useState(
    getEmbedVideoUrl(sampleVideos[0].youtubeLink)
  );
  const [bgColor, setBgColor] = useState(getRandomColor());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBgColor(getRandomColor());
    }, 2000); // Change color every 2 seconds

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, []);
  return (
    <MainContainer>
      <Box
        sx={{
          flexGrow: 1,
          border: "2px",
          padding: "2px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Paper
              elevation={12}
              sx={{
                height: "780px",
                width: "1220px flex",
              }}
            >
              <iframe
                src={selectedVideo}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  width: "100%",
                  height: "100%",
                  border: `4px solid ${getRandomLightColor}`,
                  borderRadius: "10px",
                  transition: "background-color 1s ease-in-out",
                  background: "#596FB7",
                }}
                title="Video Player"
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Card
              elevation={12}
              sx={{ background: getRandomLightColor, maxHeight: "760px " }}
            >
              <List
                sx={{
                  maxHeight: "760px flex",
                  overflow: "auto",
                  padding: "18px",
                }}
              >
                {sampleVideos.map((video, index) => (
                  <React.Fragment key={video.id}>
                    <ListItem
                      button
                      sx={{
                        border: `2px solid ${getRandomLightColor()}`,
                        borderRadius: "10px",
                        spacing: "6",
                        background: "#11235A",
                      }}
                      onClick={() =>
                        setSelectedVideo(getEmbedVideoUrl(video.youtubeLink))
                      }
                    >
                      <ListItemText
                        primary={`Video ${video.title}`}
                        sx={{
                          color: "#C499F3",
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      />
                      <Box
                        component="img"
                        sx={{
                          width: "60px",
                          height: "40px",
                          border: `2px solid ${getRandomLightColor()} `,
                        }}
                        src={`https://img.youtube.com/vi/${extractVideoID(
                          video.youtubeLink
                        )}/0.jpg`}
                        alt={`Video Thumbnail ${video.id}`}
                      />
                    </ListItem>
                    {index < sampleVideos.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </MainContainer>
  );
};

export default YouTubeClone;
