import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import Picker from "emoji-picker-react";

const socket = io.connect("http://localhost:4000"); // Adjust to your server's address

function ChatApp() {
  const [open, setOpen] = useState(true);
  const [userName, setUserName] = useState("");
  const [designation, setDesignation] = useState("");
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    socket.on("userJoined", (user) => {
      setUsers((prevUsers) => [...prevUsers, user]);
    });

    socket.on("message", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.off("userJoined");
      socket.off("message");
    };
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleJoinChat = () => {
    const newUser = { name: userName, designation };
    socket.emit("joinChat", newUser);
    setOpen(false);
  };

  const handleSendMessage = () => {
    if (message.trim()) {
      const newMessage = { userName, message, designation };
      socket.emit("sendMessage", newMessage);
      setMessage("");
      setShowEmojiPicker(false);
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    console.log("Emoji selected:", emojiObject.emoji);
    if (emojiObject) {
      console.log("Emoji selected:", emojiObject.EmojiEmotionsIcon); // Check if the emoji property exists
      setMessage((prevMessage) => prevMessage + emojiObject.emoji);
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      <Dialog open={open} onClose={() => {}}>
        <DialogTitle>Enter Your Details</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Designation"
            type="text"
            fullWidth
            variant="outlined"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleJoinChat}>Enter Chat</Button>
        </DialogActions>
      </Dialog>

      <Paper elevation={3} sx={{ width: "250px", overflow: "auto" }}>
        <Typography variant="h6" sx={{ m: 2 }}>
          Users
        </Typography>
        <List>
          {users.map((user, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <Avatar>{user.name.charAt(0)}</Avatar>
              </ListItemIcon>
              <ListItemText primary={`${user.name} (${user.designation})`} />
            </ListItem>
          ))}
        </List>
      </Paper>

      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
          {messages.map((msg, index) => (
            <Typography key={index} variant="body1" sx={{ mb: 1 }}>
              <strong>
                {msg.userName} ({msg.designation}):
              </strong>{" "}
              {msg.message}
            </Typography>
          ))}
          <div ref={messagesEndRef} />
        </Box>
        <Box
          sx={{
            p: 2,
            borderTop: "1px solid #ccc",
            display: "flex",
            alignItems: "center",
          }}
        >
          {showEmojiPicker && (
            <Picker
              onEmojiClick={onEmojiClick}
              pickerStyle={{
                position: "absolute",
                bottom: "50px",
                right: "20px",
              }}
            />
          )}
          <IconButton onClick={() => setShowEmojiPicker((val) => !val)}>
            <EmojiEmotionsIcon />
          </IconButton>
          <TextField
            fullWidth
            label="Message"
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => (e.key === "Enter" ? handleSendMessage() : null)}
            sx={{ mx: 1 }}
          />
          <Button variant="contained" onClick={handleSendMessage}>
            Send
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ChatApp;
