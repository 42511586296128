import React from 'react';
import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton } from '@mui/material';
import { GetApp as GetAppIcon, PictureAsPdf as PdfIcon, Description as WordIcon } from '@mui/icons-material';


    // Define your table data
    const data = [
      {
        title: 'Templet pdf format',
        content: 'i. AAO initially fixed under FR 22(a)(i) and later on FR 22B',
        pdfLink: '/path/to/pdf-file.pdf',
        wordLink: '/path/to/word-file.docx',
      },
      // Add more rows as needed
    ];


const SecondLevelCards=()=>{
    
    return ( 
        <Container maxWidth="md">
      <Typography variant="h4" align="center" gutterBottom>
        Templets
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Template</TableCell>
              <TableCell>Content</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.content}</TableCell>
                <TableCell>
                  <IconButton component="a" href={row.pdfLink} target="_blank" download>
                    <PdfIcon />
                  </IconButton>
                  <IconButton component="a" href={row.wordLink} target="_blank" download>
                    <WordIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
     );
}

export default SecondLevelCards;