// Import React and necessary Material UI components and icons
import React, { useState } from "react";
import SplashScreen from "./SplashScreen";
import CardGrid from "./UI/CardinDispaly";
import { Box } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import AppBar from "./UI/Appbar";
import SecondLevelCards from "./UI/SecondLevelCard";
import SecondGrid from "./UI/second";
import ThirdPage from "./UI/third";
import MyBreadcrumbs from "./UI/BreadNav";
import DataDisplayTable from "./UI/Links Display";
import DisplayImage from "./UI/DisplayImage";
import UnderConstruction from "./UI/UnderConstruction";
import ProductCard from "./UI/VideoGallery";
import VideoGallery from "./UI/VideoGallery";
import ChatApp from "./CharApp/ChatApp";

// Define the App component that renders the ResponsiveGrid
function App() {
  const [showSplash, setShowSplash] = useState(true);

  const handleCloseSplashScreen = () => {
    setShowSplash(false);
  };
  return (
    <>
      {/* <SplashScreen open={showSplash} onClose={handleCloseSplashScreen} /> */}

      <Router>
        <AppBar position="static"></AppBar>
        <Box sx={{ background: "#333", p: 1 }}>
          <MyBreadcrumbs />
        </Box>
        <Routes>
          {" "}
          {/* <-- Change here */}
          <Route exact path="/" element={<CardGrid />} />{" "}
          {/* <-- Change here */}
          <Route path="/cards/:index" element={<CardGrid />} />{" "}
          {/* <-- Change here */}
          <Route path="/secondinner" element={<SecondLevelCards />} />{" "}
          {/* <-- Change here */}
          <Route path="/:title" element={<SecondGrid />} />
          <Route path="/:title/:subTitle" element={<ThirdPage />} />
          {/* <Route path="/links" element={<DataDisplayTable />} /> */}
          {}
          <Route path="/album" element={<DisplayImage />} />
          <Route path="/under" element={<UnderConstruction />} />
          <Route path="/video" element={<VideoGallery />} />
          <Route path="/chat" element={<ChatApp />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
