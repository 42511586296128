import {
  Card,
  CardContent,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Avatar,
  Dialog,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LogoImage from "./log.jpg";
import LikeCounter from "./visitors";
import React, { useEffect, useState } from "react";

export default function AppBars() {
  const [visitorCount, setVisitorCount] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Fetch the actual visitor count from your backend or service
    // For this example, we're just simulating an increment on each app load
    const currentCount = parseInt(
      localStorage.getItem("visitorCount") || "0",
      10
    );
    const newCount = currentCount + 1;
    localStorage.setItem("visitorCount", newCount.toString());
    setVisitorCount(newCount);
  }, []);

  const LogoButton = styled(IconButton)({
    marginRight: "21px", // Space between logo and navigation items
  });

  const NavigationLink = styled(Button)({
    margin: "0 8px", // Space between navigation items
  });
  const StyledAppBar = styled(AppBar)({
    backgroundColor: "#fff", // Adjust the color to match your branding
    color: "#000", // Adjust text/icon color to match your branding
  });
  const handleopen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <StyledAppBar position="relative" sx={{ display: "flex" }}>
        <Toolbar sx={{ display: "flex", background: "#0766AD" }}>
          <LogoButton
            sx={{ display: "flex" }}
            edge="end"
            color="inherit"
            aria-label="logo"
          >
            <Avatar
              src={LogoImage}
              sx={{ height: "50px", width: "55px", display: "flex" }}
              alt="Audit Insights Logo"
              variant="circular"
            />
          </LogoButton>
          <Typography variant="h6" color="white" style={{ flexGrow: 1 }}>
            AUDIT INSIGHTS
          </Typography>
          <NavigationLink color="inherit">
            <Typography variant="body" color="white" style={{ flexGrow: 1 }}>
              VISITORS
            </Typography>
            <LikeCounter count={visitorCount} />
          </NavigationLink>
          <NavigationLink color="inherit" onClick={handleopen}>
            <Typography variant="body" color="white" style={{ flexGrow: 1 }}>
              Contact Us
            </Typography>
          </NavigationLink>
        </Toolbar>
      </StyledAppBar>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: {
            overflowY: "unset",
            backgroundColor: "#6C63FF", // Replace with your desired purple color
            color: "#FFFFFF",
            textAlign: "center",
            borderRadius: 8,
            padding: "16px",
          },
        }}
      >
        <Card sx={{ maxWidth: 345 }}>
          <CardContent>
            <Typography gutterBottom variant="h4" component="h2">
              AUDIT INSIGHTS
            </Typography>
            <Avatar
              alt="Essie Walton"
              src={LogoImage} // Replace with your actual image import
              sx={{
                width: 150,
                height: 150,
                margin: "4px auto",
              }}
            />
            <Typography
              variant="body1"
              component="p"
              sx={{ color: "#6C63FF", mt: 1 }}
            >
              Feel Free to Contact
            </Typography>
            <Typography
              variant="h6"
              component="p"
              sx={{ color: "#D65151", mt: 1 }}
            >
              stateauditjournal@gmail.com
            </Typography>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
}
