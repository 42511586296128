import React, { useState, useRef } from "react";
import {
  Card,
  Paper,
  CardContent,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import LogoImage from "./log.jpg";
import DownloadingIcon from "@mui/icons-material/Downloading";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MarkChatReadTwoToneIcon from "@mui/icons-material/MarkChatReadTwoTone";
import Diversity3SharpIcon from "@mui/icons-material/Diversity3Sharp";
import LinkTwoToneIcon from "@mui/icons-material/LinkTwoTone";
import AssuredWorkloadSharpIcon from "@mui/icons-material/AssuredWorkloadSharp";
import SavingsSharpIcon from "@mui/icons-material/SavingsSharp";
import CasesSharpIcon from "@mui/icons-material/CasesSharp";
import OndemandVideoSharpIcon from "@mui/icons-material/OndemandVideoSharp";
import PhotoLibrarySharpIcon from "@mui/icons-material/PhotoLibrarySharp";
import { useNavigate } from "react-router-dom";
import Scroll from "./scrolinh";
import FeedbackButton from "./Feedback";

// Define JSON data for the card properties
const jsonData = [
  {
    id: 1,
    title: "Articles",
    content: "An assortment of articles on various topics regularly. ",
    logoSrc: <MenuBookIcon />, // Path to the logo image
    color: "#363795", // Example gradient
  },
  {
    id: 2,
    title: "Templates",
    content: "Browse through the ready-made templates and forms.",
    logoSrc: <DownloadingIcon />, // Path to the logo image
    color: "#1B4242",
  },
  {
    id: 3,
    title: "Chatroom",
    content: "Feel free to post queries or discuss and share opinions.",
    logoSrc: <MarkChatReadTwoToneIcon />, // Path to the logo image
    color: "#348F50",
  },
  {
    id: 4,
    title: "Discussion Forum",
    content: "Indulge in focused discussions on a particular topic.",
    logoSrc: <Diversity3SharpIcon />, // Path to the logo image
    color: "#F09819",
  },
  {
    id: 5,
    title: "Links",
    content: "Multitude of links to external websites.",
    logoSrc: <LinkTwoToneIcon />, // Path to the logo image
    color: "#3CA55C",
  },

  {
    id: 6,
    title: "GOs",
    content: "Meticulously curated repository catering to your quest.",
    logoSrc: <AssuredWorkloadSharpIcon />, // Path to the logo image
    color: "#DD2476",
  },

  {
    id: 7,
    title: "Para Bank",
    content: "Well drafted model paras-ready to use.",
    logoSrc: <SavingsSharpIcon />, // Path to the logo image
    color: "#1A2980",
  },
  {
    id: 8,
    title: "Case Studies",
    content: "Acquaint yourself with exemplary audit findings.",
    logoSrc: <CasesSharpIcon />, // Path to the logo image
    color: "#26D0CE",
  },
  {
    id: 9,
    title: "Videos",
    content: "We provide you with videos by expert mentors.",
    logoSrc: <OndemandVideoSharpIcon />, // Path to the logo image
    color: "#2c3e50",
  },
  {
    id: 10,
    title: "Photo Gallery",
    content: "An assortment of pictures both academic and of events.",
    logoSrc: <PhotoLibrarySharpIcon />, // Path to the logo image
    color: "#0766AD",
  },
  {
    id: 11,
    title: "FAQs",
    content: "Just like the acronym Frequently Asked Questions.",
    logoSrc: <LiveHelpIcon />, // Path to the logo image
    color: "#0766AD",
  },
];

const MainContainer = styled("div")({
  border: "22px solid #0766AD",
  borderRadius: "7px", // Adjusted the border for simplicity
  padding: "1px",
  marginBlock: "6",
  display: "flex",
  boxSizing: "border-box",
  width: "100% flex",
  background: "#CDF5FD",
});

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: "95%",
  border: "1px solid #ccc",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    transform: "scale(1.55)", // Reduced scale for better UX
  },
  transition: "transform 0.3s ease-in-out",
}));

const CardHeader = styled(Paper)(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor,
  color: "white",
  padding: theme.spacing(1.5),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const FlipCardContent = styled(CardContent)({
  backfaceVisibility: "visible",
  transition: "transform 0.6s",
  transformStyle: "preserve-3d",
});
const getRandomLightColor = () => {
  const hue = Math.floor(Math.random() * 360);
  const saturation = Math.floor(Math.random() * (100 - 50) + 50); // Between 50-100%
  const lightness = Math.floor(Math.random() * (100 - 70) + 70); // Between 70-100%
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};
const CustomCard = ({ card }) => {
  let navigate = useNavigate();
  const [flipped, setFlipped] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  const contentRef = useRef(null);

  const handleClick = (cid, title) => {
    setFlipped(!flipped);
    navigate(`/${title}`, { state: { cid } });
  };

  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={10}
      onClick={() => handleClick(card.id, card.title)}
    >
      <StyledCard raised={flipped}>
        <CardHeader bgcolor={card.color} elevation={3}>
          <Avatar src={LogoImage} />
          <Typography
            sx={{ fontSize: "16 flex", fontWeight: "bold" }}
            component="h5"
            color="white"
          >
            {card.title}
          </Typography>
          {card.logoSrc}
        </CardHeader>
        <FlipCardContent
          style={{ transform: flipped ? "rotateY(90deg)" : "rotateY(0deg)" }}
        >
          <div
            ref={contentRef}
            style={{ maxHeight: zoomed ? "auto" : "100px", overflow: "auto" }}
          >
            <Typography variant="body2" component="p">
              {card.content}
            </Typography>
          </div>
        </FlipCardContent>
      </StyledCard>
    </Grid>
  );
};

export default function CardGrid() {
  return (
    <MainContainer>
      <Box sx={{ flexGrow: 2 }}>
        <Scroll />
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <Card
              raised
              sx={{
                padding: "6px",
                marginBlock: "0",
                elevation: 12,
                display: "-ms-flexbox",
                boxSizing: "border-box",
                background: "linear-gradient(35deg, #596FB7 25%, #176B87 90%)", // Example gradient
                borderRadius: "26px",
                border: "2px solid #86B6F6",
                my: 0,
              }}
            >
              {/* Card Media for the Image */}

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100px"
              >
                <Avatar
                  alt="Audit Insights"
                  elevation="12"
                  variant="circular"
                  src={LogoImage} // Replace with the path to your image
                  sx={{
                    width: 100,
                    height: 100,
                    border: "1px solid", // Adjust border size as needed
                    borderColor: "#58A6FF",
                  }}
                />
              </Box>
              {/* Card Content for text */}
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  align="justify"
                  color="white"
                >
                  About the Blog
                </Typography>
                <Typography
                  variant="body2"
                  color="white"
                  align="justify"
                  fontFamily="sans-serif"
                >
                  The blog highlights the importance of audio, visual aids, and
                  quality materials in the field of auditing, particularly for
                  local government bodies and works audit. It emphasizes the
                  need for auditors to have a strong foundation in audit
                  principles and application-oriented knowledge, allowing them
                  to confidently conduct audits in the dynamic and ever-evolving
                  field.
                </Typography>
                {/* List of features */}
                <List dense>
                  {[
                    "Foundation and Knowledge: Auditors need a solid foundation in audit principles and specific guidance to conduct audits effectively.",
                    "Continuous Learning: The blog underscores the importance of continuous learning and skill development for auditors.",
                    "Understanding Changing Laws: Auditors should stay up-to-date with new and dynamic laws relevant to their field.",
                    "Disclaimer: The author of the blog disclaims responsibility for the correctness of the information provided.",
                    "Open to Feedback: The blog encourages suggestions for improvement and positive criticism, showing a willingness to learn and evolve in response to feedback.",
                    "Acknowledgment of Contributors: The blog acknowledges the collective effort of many individuals who contribute to the field of auditing, recognizing their invaluable contributions",
                    "Importance of Training: Training is emphasized as a crucial component of maintaining the credibility and professionalism of audit personnel. ",
                    "In summary, the blog highlights the dynamic and knowledge-intensive nature of the auditing profession, emphasizing the need for auditors to continuously update their skills and knowledge to meet evolving challenges.",
                    // ... Add all other points here
                  ].map((text, index) => (
                    <ListItem key={index} sx={{ py: 0, px: 1, color: "white" }}>
                      <ListItemText
                        primary={`• ${text}`}
                        primaryTypographyProps={{
                          sx: {
                            textAlign: "justify",
                            fontFamily: "sans-serif",
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} md={8}>
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                alignItems: "self-start",
                marginLeft: "30px",
              }}
            >
              {jsonData.map((card, index) => (
                <Grid
                  item
                  xs={4}
                  sm={8}
                  md={8}
                  lg={4}
                  key={card.id}
                  sx={{ display: "flex", marginTop: 2 }}
                >
                  <CustomCard card={card} />
                </Grid>
              ))}
            </Grid>
            <FeedbackButton />
          </Grid>
        </Grid>
      </Box>
    </MainContainer>
  );
}
